import { useState, useRef, useEffect } from 'react'
import photo from './reddit-photo.png'
import reddit_users from './reddit users.png'
import mobile_photo from './mobile-photo.png'
import jsonp from 'jsonp'
import './App.css'

function App() {
	const [username, setUsername] = useState('')
	const [email, setEmail] = useState('')
	const [submitted, setSubmitted] = useState(false)
	const [res, setRes] = useState([])
	const [loading, setLoading] = useState(false)
	const [learnMore, setLearnMore] = useState(false)
	const [analyzed, setAnalyzed] = useState(false)
	const resultsContainerRef = useRef(null)
	const learnedMoreRef = useRef(null)

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search)
		const usernameParam = queryParams.get('u')
		console.log(usernameParam)
		if (usernameParam) {
			setUsername(usernameParam)
		}
	}, [])

	useEffect(() => {
		if (res.length > 0 && resultsContainerRef.current) {
			resultsContainerRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [res])

	useEffect(() => {
		if (learnedMoreRef.current) {
			learnedMoreRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [learnMore])

	function onSubmit(e) {
		e.preventDefault()
		const url =
			'https://club.us13.list-manage.com/subscribe/post?u=db2f26b10cc3c97a0f8e88566&amp;id=a1ddac5553&amp;f_id=00baede7f0'

		jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {})
		setSubmitted(true)
	}

	function onEmailChange(e) {
		setEmail(e.target.value)
	}

	function onLearnMore() {
		setLearnMore(true)
	}

	function onUsernameChange(e) {
		setUsername(e.target.value)
	}

	function onButtonClick() {
		setLoading(true)

		fetch('https://onlygrow-reddit-da62dbae868c.herokuapp.com/analyze', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ username: username }),
		})
			.then((response) => response.json())
			.then((data) => {
				setLoading(false)
				setRes(data.slice(0, 3))
				if (resultsContainerRef.current) {
					resultsContainerRef.current.scrollIntoView({ behavior: 'smooth' })
				}
				setAnalyzed(true)
			})
			.catch((err) => setLoading(false))
	}

	function calculate_rating() {
		return (6 + (username.length % 10) * 0.09).toFixed(1)
	}

	function render_analysis() {
		return (
			<div className='second-page'>
				<div className='results-container' ref={resultsContainerRef}>
					<p id='report'>{username}'s Report</p>

					<div className='post'>
						<img src={res[0].img} alt='Post related' className='post-image' />

						<div className='post-info'>
							<p className='post-count'>recent posts:</p>
							{res.map((post, index) => (
								<div key={index} className='post-detail'>
									<div>
										<p className='posts-info'>
											<span className='field-name'>Post Title:</span>{' '}
											{post.title}
										</p>
									</div>
									<div>
										<p className='posts-info'>
											<span className='field-name'>Subreddit:</span>{' '}
											{post.subreddit}
										</p>
									</div>
									<div>
										<p className='posts-info'>
											<span className='field-name'>Upvotes:</span>{' '}
											{post.upvotes}
										</p>
									</div>
									<div>
										<p className='posts-info'>
											<span className='field-name'>Posted on:</span>{' '}
											{post.posted_at} (UTC)
										</p>
									</div>
								</div>
							))}
						</div>
						<p id='score-txt'>Overall Reddit Strategy Score:</p>
						<p id='score'>
							{calculate_rating()} <span id='ten'>/ 10</span>
						</p>
					</div>
				</div>
				<div className='cta'>
					{learnMore ? (
						<div className='learnedMore' ref={learnedMoreRef}>
							<p id='no-worries'>
								Optimize Your <span id='reddit'>Reddit</span> Strategy *On
								Autopilot*
							</p>
							<br />
							<img
								id='desktop-photo'
								width='1200px'
								height='auto'
								src={photo}
								alt=''
							/>
							<img id='mobile-photo' src={mobile_photo} alt='' />

							<div className='clients'>
								<p id='our-clients'>Our Clients</p>
								<br />
								<img
									id='clients-img'
									width='600px'
									height='auto'
									src={reddit_users}
									alt=''
								/>
							</div>
							<div className='final-cta'>
								<div>
									<p id='early-adopter'>
										Enter Your Email And <br /> Get Free Access To Our $99/Month
										Tool
									</p>
								</div>

								<div className='email-section'>
									<form className='form' onSubmit={onSubmit}>
										<input
											onChange={onEmailChange}
											placeholder='example@gmail.com'
											id='email'
											type='email'
										/>
										<button type='submit' id='early-access'>
											Try It Now
										</button>
									</form>
								</div>
							</div>
						</div>
					) : (
						<>
							<p id='help'>
								We Might Have The Solution For Maximized{' '}
								<span id='reddit'>Reddit</span> Promotion
							</p>
							<button onClick={onLearnMore} id='join'>
								Learn More
							</button>
						</>
					)}
				</div>
			</div>
		)
	}
	if (!submitted) {
		return (
			<div className={res.length > 0 ? 'App' : 'App search-only'}>
				<div className='container'>
					<p id='title'>
						Optimize Your <span id='reddit'>Reddit</span> Strategy As An{' '}
						<span id='onlyfans'>OnlyFans</span> Creator
					</p>
					<p id='subtitle'>
						Enter Your Reddit Username And Get An Analysis Of Your Reddit
						Promotion Strategy
					</p>
					<p id='reddit-username'>reddit username:</p>
					<input
						value={username}
						onChange={onUsernameChange}
						id='search-bar'
						disabled
						type='text'></input>

					{!analyzed && !loading ? (
						<button onClick={onButtonClick} id='rate-btn' type='button'>
							Analyze My Reddit
						</button>
					) : (
						''
					)}
					{loading ? (
						<div className='pressed-btn'>
							<span className='loader'></span>
							<p id='analyzing'>Analyzing...</p>
						</div>
					) : (
						''
					)}
				</div>

				{res.length > 0 && !submitted ? render_analysis() : ''}
			</div>
		)
	} else {
		return (
			<div className='submited-page'>
				<p id='contact-you'>
					Thank you for submitting!
					<br />
					We will contact you as soon as possible and give you the next steps.
				</p>
			</div>
		)
	}
}

export default App
